import { useIsNonMemberContactsEnabled } from "@circle-react/hooks/useIsNonMemberContactsEnabled";
import type { RenderFilter } from "./types";

const marketingFilterNames = ["member", "subscribed_to_marketing_emails"];

export const useShouldShowMarketingFilters = (filters: RenderFilter[]) => {
  const isNonMemberContactsEnabled = useIsNonMemberContactsEnabled();

  if (isNonMemberContactsEnabled) {
    return filters;
  }

  return filters.filter(({ name }) => !marketingFilterNames.includes(name));
};
